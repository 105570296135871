import { SocialNetwork } from '../interfaces/social-network.interface';

export const SocialNetworks: SocialNetwork[] = [
  {
    name: 'instagram',
    link: 'https://www.instagram.com/riddicks.partners/',
  },
  {
    name: 'telegram',
    link: 'https://t.me/riddickspartners',
  },
  {
    name: 'linkedIn',
    link: 'https://www.linkedin.com/in/riddick-s-partners-64745b32b/',
  },
  {
    name: 'twitter',
    link: 'https://x.com/riddickpartners',
  },
  {
    name: 'facebook',
    link: 'https://www.facebook.com/share/XaTqBac5kp3V7DUf/?mibextid=LQQJ4d',
  },
];
