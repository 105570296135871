import { booleanAttribute, Component, effect, HostBinding, input } from '@angular/core';

@Component({
  selector: 'ui-divider',
  standalone: true,
  templateUrl: './divider.component.html',
  styleUrl: './divider.component.scss',
})
export class DividerComponent {
  readonly isBlurBackground = input(true, { transform: booleanAttribute });
  readonly height = input('181px');

  @HostBinding('class') class = '';
  @HostBinding('style') style = '';

  constructor() {
    effect(() => {
      if (this.isBlurBackground()) {
        this.class = 'blur-background';
      }

      if (this.height()) {
        this.style = `--divider-height: ${this.height()}`;
      }
    });
  }
}
