import { SectionIds } from '../enums';
import { MenuItem } from '../interfaces/menu-item.interface';

export const MENU_ITEMS: MenuItem[] = [
  {
    label: 'general.webmasters',
    link: '',
    sectionId: SectionIds.webmastersId,
  },
  {
    label: 'general.advertisers',
    link: '',
    sectionId: SectionIds.advertisersId,
  },
  {
    label: 'general.faq',
    link: '',
    sectionId: SectionIds.FAQ,
  },
  {
    label: 'general.contacts',
    link: '',
  },
];
