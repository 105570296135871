import { inject, Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { LANGUAGE_KEY } from '@shared/models';

import { LocalStorageService } from './local-storage.service';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  private readonly _selectedLanguageSubject: Subject<string> = new BehaviorSubject('EN');
  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly selectedLanguage$: Observable<string> = this._selectedLanguageSubject.asObservable();

  private readonly _translateService = inject(TranslateService);
  private readonly _localStorageService = inject(LocalStorageService);

  getCurrentLanguage(): string {
    const language: string =
      this._localStorageService.getValue(LANGUAGE_KEY) || this._translateService.defaultLang;
    this._selectedLanguageSubject.next(language);

    return language;
  }

  setLanguage(language: string): void {
    this._translateService.use(language);
    this._localStorageService.setValue(LANGUAGE_KEY, language);
    this._selectedLanguageSubject.next(language);
  }
}
