<ng-select
  class="ui-select {{ size() }}"
  [(ngModel)]="value"
  [items]="items()"
  [bindLabel]="bindLabel()"
  [placeholder]="placeholder() | translate"
  [clearable]="false"
  [searchable]="false"
  [closeOnSelect]="!multiple()"
  [dropdownPosition]="dropdownPosition()"
  [multiple]="multiple()"
  (blur)="onTouched()"
  (change)="onChange($event)"
  [class.invalid]="control()?.invalid && control()?.dirty"
/>

@if (control()?.invalid && control()?.dirty) {
  <ui-validation-message [errors]="control()?.errors" />
}
