<div
  class="accordion__header"
  [ngClass]="{
    active: isAnimating(),
    finished: !isAnimating(),
    expanded: isExpanded(),
  }"
>
  <h3 class="accordion__header_title">{{ title() }}</h3>

  <div class="accordion__header_toggle-icon" [ngClass]="{ active: isExpanded() }"></div>
</div>

<div
  class="accordion__content"
  [@accordionContent]="isExpanded() ? 'open' : 'closed'"
  (@accordionContent.done)="onTransitionEnd()"
>
  <ng-content select="[accordionContent]" />
</div>
