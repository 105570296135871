import { Schema } from '../interfaces/schema.interface';
import { SchemasTarget } from '../enums';
import { SchemaItem } from '../interfaces';

export const SCHEMAS: (isDesktop: boolean) => Record<SchemasTarget, Schema> = (
  isDesktop: boolean,
) => {
  const sort = (a: SchemaItem, b: SchemaItem): number => a.sequence - b.sequence;

  return {
    [SchemasTarget.forEveryone]: {
      name: 'content.forEveryone',
      items: [
        {
          name: 'forEveryone.expertiseExchange',
          icon: 'expertise-exchange',
          sequence: 0,
        },
        {
          name: 'forEveryone.handyTools',
          icon: 'handy-tools',
          sequence: isDesktop ? 1 : 2,
        },
        {
          name: 'forEveryone.statisticsDetailing',
          icon: 'statistics-detailing',
          sequence: isDesktop ? 2 : 4,
        },
        {
          name: 'forEveryone.fastPerformance',
          icon: 'fast-performance',
          sequence: isDesktop ? 3 : 6,
        },
        {
          name: 'forEveryone.giftShop',
          icon: 'gift-shop',
          sequence: isDesktop ? 4 : 1,
        },
        {
          name: 'forEveryone.multilingualSupport',
          icon: 'multilingual-support',
          sequence: isDesktop ? 5 : 3,
        },
        {
          name: 'forEveryone.legalSupport',
          icon: 'legal-support',
          sequence: isDesktop ? 6 : 5,
        },
        {
          name: 'forEveryone.helpWithSEO',
          icon: 'help-with-seo',
          sequence: 7,
        },
      ].sort(sort),
    },
    [SchemasTarget.forAffiliates]: {
      items: [
        {
          name: 'forAffiliates.individualTerms',
          icon: 'individual-terms',
          sequence: 0,
        },
        {
          name: 'content.forAffiliates',
          sequence: 1,
        },
        {
          name: 'forAffiliates.apps',
          icon: 'apps',
          sequence: 2,
        },
        {
          name: 'forAffiliates.exclusiveOffers',
          icon: 'exclusive-offers',
          sequence: 3,
        },
        {
          name: 'forAffiliates.uniquePaymentSolution',
          icon: 'unique-payment-solution',
          sequence: 4,
        },
        {
          name: 'forAffiliates.infrastructureForWork',
          icon: 'infrastructure-for-work',
          sequence: 5,
        },
      ].sort(sort),
    },
    [SchemasTarget.forAdvertisers]: {
      items: [
        {
          name: 'forAdvertisers.highQualityTraffic',
          icon: 'high-quality-traffic',
          sequence: 0,
        },
        {
          name: 'forAdvertisers.powerfulSystem',
          icon: 'powerful-system',
          sequence: isDesktop ? 1 : 2,
        },
        {
          name: 'forAdvertisers.assistance',
          icon: 'assistance',
          sequence: isDesktop ? 2 : 3,
        },
        {
          name: 'forAdvertisers.ownMediaBuying',
          icon: 'own-media-buying',
          sequence: isDesktop ? 3 : 4,
        },
        {
          name: 'content.forAdvertisers',
          sequence: isDesktop ? 4 : 1,
        },
        {
          name: 'forAdvertisers.directIntegration',
          icon: 'direct-integration',
          sequence: 5,
        },
      ].sort(sort),
    },
  };
};
